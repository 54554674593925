// Custom Input Styles
.inputContainer {
  background-color: #f5f5f5;
  position: relative;
  border-radius: 4px 4px 0 0;
  height: 56px;
  transition: background-color 500ms;
  &:hover {
    background-color: #ececec;
  }
  &:focus-within {
    background-color: #dcdcdc;
  }
}
 
label {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  color: rgba(0, 0, 0, 0.5);
  transform-origin: left top;
  user-select: none;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),color 150ms cubic-bezier(0.4, 0, 0.2, 1), top 500ms;
}

input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: white;
  caret-color: #4139A3;
  border: 1px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.42);
  color: rgba(0, 0, 0, 0.87);
  transition: border 500ms;
  padding: 20px 16px 6px;
  font-size: 1rem;


  &:focus {
    outline: none;
    border-bottom-width: 2px;
    border-bottom-color: #4139A3;
  }

  &:focus + label,
  &.hasText + label {
    color: #4139A3;
    transform: translateY(-100%) scale(0.75);
  }
}