// *******************************************************
// Layout Style File
// -------------------------------------------------------
// This file contains all the main style classes for the
// css styling across the application
// -------------------------------------------

/*
 * Basic Flex Declarations
 */
.container {
    flex: 1;
}
.grid {
    display: grid;
}
.flexCol {
    display: flex;
    flex-direction: column;
}
.flexRow {
    display: flex;
    flex-direction: row;;
}
.flexWrap {
    flex-wrap: wrap;
}

/*
 * Grid Style Declarations
 */
 .oneColumn {
     grid-template-columns: 1fr;
     column-gap: 20px;
     row-gap: 20px;
 }

.twoColumn {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
}

.threeColumn {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
}

@for $number from 0 through 100 {
    .ColumnGap_#{$number} {
        column-gap: #{$number}px;
    }
    .RowGap_#{$number} {
        row-gap: #{$number}px;
    }
}
/*
 * Primary Style Declarations
 */
.mainAxisCenter {
    justify-content: center;
}
.mainAxisStart {
    justify-content: flex-start;
}
.mainAxisEnd {
    justify-content: flex-end;
}
.mainAxisAround {
    justify-content: space-around;
}
.mainAxisBetween {
    justify-content: space-between;
}
/*
 * Secondary Style Declarations
 */
.crossAxisCenter {
    align-items: center;
}
.crossAxisStart {
    align-items: flex-start;
}
.crossAxisEnd {
    align-items: flex-end;
}
.crossAxisBetween {
    align-items: stretch;
}
/*
 * Centering on Both Axis
 */
.bothAxisCenter {
    justify-content: center;
    align-items: center;
}
/*
 * Absolute Positions
 */
.absoluteTopLeft {
    position: absolute;
    top: 0;
    left: 0;
}
.absoluteBottomLeft {
    position: absolute;
    bottom: 0;
    left: 0;
}
.absoluteTopRight {
    position: absolute;
    top: 0;
    right: 0;
}
.absoluteBottomRight {
    position: absolute;
    bottom: 0;
    right: 0;
}
.absoluteTop {
    position: absolute;
    top: 0;
}
/*
 * Align Self Classes
 */
.selfMainAxisCenter {
    align-self: center;
}
.selfStretch {
    align-self: stretch;
}
/*
 * Text Layout Positions
 */
.textCenter {
    text-align: center;
}
.textLeft {
    text-align: left;
}
.textRight {
    text-align: right;
}
/*
 * Box Sizings
 */
.boxSizing_Content {
    box-sizing: content-box;
}
.boxSizing_Border {
    box-sizing: border-box;
}
/*
 * Display Stylings
 */
.inlineBlock {
    display: inline-block;
}
.inline {
    display: inline;
}
.block {
    display: block;
}
.relative {
    position: relative;
}
.absolute {
    position: absolute;
    z-index: 10;
}
