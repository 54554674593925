// *******************************************************
// Border Generated Style File
// -------------------------------------------------------
// This file contains the generated scss for borders
// that are used throughout the application
//
// How To Use:
// Ensure your project has Sass/SCSS modules installed
// then import this file into your required component.
// The styles can then be added following the guide below.
// -------------------------------------------

// *******************************************
// Style Guide
// -------------------------------------------
//
// Inside JS File:
// import Border from '../path/to/styles/Border.scss';
//
// Border.Width_1 = border-width: 1px;
// Border.Radius_1 = border-radius: 1px;
// Border.Width_Top_1 = border-width-top: 1px;
// Border.Radius_Top_Left_1 = border-top-left-radius: 1px;
// --------------------------------

// *******************************************
// Import Statements
// -------------------------------------------
@use "./Colours" as c;
// --------------------------------

// *******************************************
// Style Implementation
// -------------------------------------------
@for $number from 0 through 100 {
    .Width_#{$number} {
        border-style: solid;
        border-width: #{$number}px;
    }
    .Radius_#{$number} {
        border-radius: #{$number}px;
    }
}

@each $name, $color, $hovercolor in c.$colors {
    .Colour_#{$name} {
        border-color: #{$color};
    }

    .Colour_#{$name}_Hover {
        border-color: #{$color};
        &:hover {
            border-color: #{$hovercolor};
        }
    }

    .Left_Colour_#{$name}_Hover {
        border-left-color: #{$color};
        &:hover {
            border-left-color: #{$hovercolor};
        }
    }

    .#{$name}Child_Hover {
        .child_Left_Hover_Target {
            border-left-color: #{$color};
        }
        &:hover .child_Left_Hover_Target {
            cursor: pointer;
            border-left-color: #{$hovercolor};
        }

        .child_Right_Hover_Target {
            border-right-color: #{$color};
        }
        &:hover .child_Right_Hover_Target {
            cursor: pointer;
            border-right-color: #{$hovercolor};
        }

        .child_Top_Hover_Target {
            border-top-color: #{$color};
        }
        &:hover .child_Top_Hover_Target {
            cursor: pointer;
            border-top-color: #{$hovercolor};
        }

        .child_Bottom_Hover_Target {
            border-bottom-color: #{$color};
        }
        &:hover .child_Bottom_Hover_Target {
            cursor: pointer;
            border-bottom-color: #{$hovercolor};
        }
    }
}

@for $number from 0 through 100 {
    .WidthTop_#{$number} {
        border-top-style: solid;
        border-top-width: #{$number}px;
    }
    .WidthBottom_#{$number} {
        border-bottom-style: solid;
        border-bottom-width: #{$number}px;
    }
    .WidthLeft_#{$number} {
        border-left-style: solid;
        border-left-width: #{$number}px;
    }
    .WidthRight_#{$number} {
        border-right-style: solid;
        border-right-width: #{$number}px;
    }
    .RadiusTopLeft_#{$number} {
        border-top-left-radius: #{$number}px;
    }
    .RadiusTopRight_#{$number} {
        border-top-right-radius: #{$number}px;
    }
    .RadiusBottomLeft_#{$number} {
        border-bottom-left-radius: #{$number}px;
    }
    .RadiusBottomRight_#{$number} {
        border-bottom-right-radius: #{$number}px;
    }
}
// --------------------------------
