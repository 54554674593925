// *******************************************************
// Background Generated SCSS File
// -------------------------------------------------------
// This file contains the generated scss for setting different
// Background colors through-out the application.
//
// How To Use:
// Ensure your project has Sass/SCSS modules installed
// then import this file into your required component.
// The styles can then be added following the guide below.
// -------------------------------------------

// *******************************************
// Style Guide
// -------------------------------------------
// Background.white = background-color: #FFFFFF;
// Background.white_Hover = background-color: #FFFFFF (And hover state)
// --------------------------------

// *******************************************
// Child Hover Guide
// -------------------------------------------
//
// You can target a chidl hover background color from a parent element,
// this can be done by applying the #{color}ChildHover class to the parent
// element, then applying the .childHoverTarget class to any children
// classes you want this to effect.
//
// EXAMPLE:
//
// <div className={[Background.greenHover, Background.white_Child_Hover].join(" ")}>
//     <div className={[Background.child_Hover_Target].join(" ")}></div>
// </div>
//
// This will cause the outer div to toggle between the different green hover colors,
// however it will also cause the inner div to have a white background when the
// parent is hovered over. This is very useful when trying to create buttons with
// inverted color schemes when you hover over the parent element
// --------------------------------

// *******************************************
// Import Statements
// -------------------------------------------
@use "./Colours" as c;
// --------------------------------

// *******************************************
// Style Implementation
// -------------------------------------------
@each $name, $color, $hovercolor in c.$colors {
    
  .#{$name} {
      background-color: #{$color};
  }

  .#{$name}_Hover {
      background-color: #{$color};
      &:hover {
          cursor: pointer;
          background-color: #{$hovercolor};
      }
  }

  .#{$name}_Child_Hover {
      .child_Hover_Target {
          background-color: #{$color};
      }
      &:hover .child_Hover_Target {
          cursor: pointer;
          background-color: #{$hovercolor};
      }
  }

}
// --------------------------------
