// *******************************************************
// Noramlise SCSS File
// -------------------------------------------------------

// -------------------------------------------

// *******************************************
// Style Implementation
// -------------------------------------------
.Y {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox 64 */
    -ms-overflow-style: none; /* Internet Explorer 11 */
    &::-webkit-scrollbar { /** WebKit */
        display: none;
    }
}
// --------------------------------
