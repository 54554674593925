// *******************************************************
// Fill Generated SCSS File
// -------------------------------------------------------
// This file contains the generated scss for setting different
// Background colors through-out the application.
//
// How To Use:
// Ensure your project has Sass/SCSS modules installed
// then import this file into your required component.
// The styles can then be added following the guide below.
// -------------------------------------------

// *******************************************
// Style Guide
// -------------------------------------------
//
// Inside JS File:
// import Fill from '../path/to/styles/Fill.scss';
//
// Fill.white = fill: #FFFFFF;
// Fill.whiteHover = fill: #FFFFFF (And hover state)
// --------------------------------

// *******************************************
// Child Hover Guide
// -------------------------------------------
//
// You can target a chidl hover background color from a parent element,
// this can be done by applying the #{color}ChildHover class to the parent
// element, then applying the .childHoverTarget class to any children
// classes you want this to effect.
//
// EXAMPLE:
//
// <div className={[Fill.greenHover, Fill.whiteChildHover].join(" ")}>
//     <div className={[Fill.childHoverTarget].join(" ")}></div>
// </div>
//
// This will cause the outer div to toggle between the different green hover colors,
// however it will also cause the inner div to have a white background when the
// parent is hovered over. This is very useful when trying to create buttons with
// inverted color schemes when you hover over the parent element
// --------------------------------

// *******************************************
// Import Statements
// -------------------------------------------
@use "./Colours" as c;
// --------------------------------

// *******************************************
// Style Implementation
// -------------------------------------------
@each $name, $color, $hovercolor in c.$colors {

  .#{$name} {
      fill: #{$color};
  }
  .#{$name}_Hover {
      fill: #{$color};
      &:hover {
          cursor: pointer;
          fill: #{$hovercolor};
      }
  }
  .#{$name}ChildHover {
      cursor: pointer;
      .childHoverTarget {
          cursor: pointer;
          fill: #{$color};
      }
      &:hover .childHoverTarget {
          cursor: pointer;
          fill: #{$hovercolor};
      }
  }
}
// --------------------------------
