// *******************************************************
// Font Generated Style File
// -------------------------------------------------------
// This file contains the generated scss for fonts
// that are used throughout the application
//
// How To Use:
// Ensure your project has Sass/SCSS modules installed
// then import this file into your required component.
// The styles can then be added following the guide below.
// -------------------------------------------

// *******************************************
// Style Guide
// -------------------------------------------
//
// Inside JS File:
// import Font from '../path/to/styles/Font.scss';
//
// Font.normal = Will set just the font family
// Font.bold = Will add bold to the element
// Font.italic = Will add italic to the element
// Font.boldItalic = Will add both bold/italic to the element
// Font.Size_1 = font-size: 1px;
// Font.Color_white = color: white;
// --------------------------------

// *******************************************
// Import Statements
// -------------------------------------------
@use "./Colours" as c;

@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file+'.eot');
    src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
    url($font-file+'.woff') format('woff'),
    url($font-file+'.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}

@include font('Montserrat-Regular', '../_assets/_fonts/Montserrat-Regular');
@include font('Montserrat-Medium', '../_assets/_fonts/Montserrat-Medium');
@include font('Montserrat-Bold', '../_assets/_fonts/Montserrat-Bold');
// --------------------------------

// *******************************************
// Style Implementation
// -------------------------------------------
.Montserrat {
    font-family: 'Montserrat-Regular', sans-serif;
}

.Montserrat_Medium {
    font-family: 'Montserrat-Medium', sans-serif;
}

.Montserrat_Bold {
    font-family: 'Montserrat-Bold', sans-serif;
}

.underline {
    text-decoration: underline;
}

.underline_Hover {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.pointer {
    cursor: pointer;
}

@each $name, $color, $hovercolor in c.$colors {
  .Colour_#{$name} {
      color: #{$color};
  }
  .Colour_#{$name}_Hover {
      color: #{$color};
      &:hover {
          cursor: pointer;
          color: #{$hovercolor};
      }
  }
  .Colour_#{$name}_Child_Hover {
      cursor: pointer;
      color: #{$color};
      &:hover .child_Hover_Target {
          cursor: pointer;
          color: #{$hovercolor};
      }
  }
}

@for $number from 0 through 100 {
    .Size_#{$number} {
        font-size: #{$number}px;
    }
}

.LineHeight_Normal {
    line-height: normal;
}

$lineHeight: 1;
.LineHeight_10 {
    line-height: $lineHeight;
}
@while $lineHeight < 2.1 {
  $lineHeight: $lineHeight + 0.1;
  $index: $lineHeight * 10;
  .LineHeight_#{$index} {
      line-height: $lineHeight;
  }
}
// --------------------------------
