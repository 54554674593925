// *******************************************************
// Margin Generated Style File
// -------------------------------------------------------
// This file contains the generated scss for margins
// that are used throughout the application
//
// How To Use:
// Ensure your project has Sass/SCSS modules installed
// then import this file into your required component.
// The styles can then be added following the guide below.
// -------------------------------------------
@use "sass:math";
// *******************************************
// Style Guide
// -------------------------------------------
//
// Inside JS File:
// import Width from '../path/to/styles/Width.scss';
//
// Width._1 = width: 1%;
// --------------------------------

// *******************************************
// Style Implementation
// -------------------------------------------
@for $number from 0 through 100 {
    ._#{$number} {
        height: #{$number}vh;
    }
    .Min_#{$number} {
        min-height: percentage(math.div($number, 100));
    }
    .Min_Fixed_#{$number} {
        min-height: #{$number}vh;
    }
}
// --------------------------------
